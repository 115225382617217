<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">气象</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="时间" prop="FBSJ">
                    <el-date-picker
                        v-model="formdata.dataDic.FBSJ"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime"
                        :disabled="!isEdit"
                        placeholder="请选择时间"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="温度" prop="DQQW">
                    <el-input
                        v-model="formdata.dataDic.DQQW"
                        placeholder="请输入温度"
                        @input="toDecimal($event, 'DQQW')"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">°C</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="湿度" prop="DQSD">
                    <el-input
                        v-model="formdata.dataDic.DQSD"
                        placeholder="请输入湿度"
                        @input="toDecimal($event, 'DQSD')"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">%</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="风向" prop="FX">
                    <el-input
                        v-model="formdata.dataDic.FX"
                        placeholder="请输入风向"
                        @input="toDecimal($event, 'FX')"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">度</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="风力" prop="FL">
                    <el-input
                        v-model="formdata.dataDic.FL"
                        placeholder="请输入风力"
                        :maxlength="20"
                        :disabled="!isEdit"
                    >
                        <template slot="append"></template>
                    </el-input>
                </el-form-item>
                <el-form-item label="降水量" prop="JYL_24H">
                    <el-input
                        v-model="formdata.dataDic.JYL_24H"
                        placeholder="请输入降水量"
                        @input="toDecimal($event, 'JYL_24H')"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">mm</template>
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../../0_com_js/info_mixin.js";
export default {
    name: "zr_qx",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "8020101",
                dataDic: {
                    FBSJ: "",
                    DQQW: "",
                    DQSD: "",
                    FX: "",
                    FL: "",
                    JYL_24H: "",
                },
            },
            SGLXList: [],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style></style>
